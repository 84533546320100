import { createStore } from 'vuex'
import axios from 'axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

export default createStore({
  state: {
    artList: [], //Objekt-Array von  Weizenarten
    felder: [], //Objekt-Array von Feldern/Filtern für aktuelle Weizenart
    threadList: { //ID der aktuellen Weizenart sowie zugehörige Liste von Produkten
      Key: 326,
      list: []
    },
    sorting: {
      Key: '',
      order: 'ASC'
    }
  },
  mutations: {
    initArtList(state, artList){
      state.artList = artList
    },
    updateThread(state, thread){      
      state.threadList.Key = thread.Key
      state.threadList.list = thread.dataList
    },
    resetFelder(state, felder){
      state.felder = felder
    },
    updateSorting(state, sorting){
      state.sorting = sorting
    }
  },




  actions: {
    init({commit, dispatch}){
      axios('/johnsons/kuposaat/getSIarten.json')
      .then(response => {
        if(response.data.artList.length && response.data.artList && response.data.artList.length > 0){
          commit('initArtList', response.data.artList)
          dispatch('updateThread', response.data.artList[0].ArtNr)
        }
      })
    },
    updateThread({commit, dispatch}, id){
      const thread = {
        Key: Number,
        dataList: []
      }
      
      thread.Key = id
      
      axios('/johnsons/kuposaat/getSI_threads-' + id + '.json')
      .then(response => {
        if(response.data.list.length && response.data.list[0] && response.data.list[0].Value && response.data.list[0].Value.length > 0){
          response.data.list[0].Value.forEach(function(entry: any){
            entry.Felder.forEach(function(feld: any, idx: any){
              entry.Felder[feld.Key] = feld.Value
              entry.active = false
              delete entry.Felder[idx]
            })
          })
          thread.dataList = response.data.list[0].Value
          dispatch('resetFelder', id)
          // console.log(thread.dataList)
          commit('updateThread', thread)
        }
      })
    },
    resetFelder({commit, dispatch}, id){
      axios('/johnsons/sorteninfo/felder/get-' + id + '.json')
      .then(response => {
        if(response.data && response.data.data && response.data.data.length > 0){


          const responseByKey = lodash.reduce(response.data.data , function(obj: any, param: any) {
            obj[param.Key] = param
            obj[param.Key].selectedFilter = obj[param.Key].FilterVal.slice()
            obj[param.Key].comparing = (obj[param.Key].Key === 'sorte')
            return obj
           }, {})
          //  console.log('this.felder:')
          //  console.log(responseByKey)
           Object.values(responseByKey).forEach(function(singleFeld: any){
            if(singleFeld.Position === 1){
              const sorting = {Key: singleFeld.Key, order: 'ASC'}
              commit('updateSorting', sorting)
            }
           })


          commit('resetFelder', responseByKey)
        }
      })
    }
  },
  modules: {
  },
  getters: {
    getThreadId: state => state.threadList.Key
  }
})
